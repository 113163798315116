import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  connections,
  connectionsForm,
  businesss,
  businesssForm,
  locations,
  locationsForm,
} from "./Reducers";

export default combineReducers({
  locations,
  locationsForm,
  businesss,
  businesssForm,
  connections,
  connectionsForm,
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
});
