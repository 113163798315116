import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_BUSINESS,
    DELETE_BUSINESS,
    BUSINESS_FORM_TOOGLE_LOADING,
    SET_BUSINESS_LIST,
    SHOW_NOTIFICATION,
    UPDATE_BUSINESS,
} from "../constants";
import { formatBusinesss } from "./settingsActionsUtils";

/* BUSINESS LIST */
export const fetchBusinesss = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.businesss.list)
            .then((response) => {
                const businesss = formatBusinesss(response.data);
                dispatch({
                    type: SET_BUSINESS_LIST,
                    payload: keyBy(businesss, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// BUSINESSS
export const createBusiness = (newBusiness) => {
    return async (dispatch) => {
        dispatch({ type: BUSINESS_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.businesss.create, newBusiness)
            .then((response) => {
                const business = formatBusinesss(response.data);
                dispatch({ type: ADD_NEW_BUSINESS, payload: business });
                dispatch({ type: BUSINESS_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Empresa creado con éxito.",
                    },
                });
                return response?.status;
            })
            .catch((err) => {
                dispatch({ type: BUSINESS_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateBusiness = (updatedBusiness) => {
    return async (dispatch) => {
        dispatch({ type: BUSINESS_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.businesss.edit}${updatedBusiness?._id}`, updatedBusiness)
            .then((response) => {
                const business = formatBusinesss(response.data);
                dispatch({ type: UPDATE_BUSINESS, payload: business });
                dispatch({ type: BUSINESS_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Empresa actualizado.",
                    },
                });
                return response?.status;
            })
            .catch((err) => {
                dispatch({ type: BUSINESS_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteBusinesss = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: BUSINESS_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.businesss.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_BUSINESS, payload: Ids });
                dispatch({ type: BUSINESS_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Empresa eliminado.",
                    },
                });
                return response?.status;
            })
            .catch((err) => {
                dispatch({ type: BUSINESS_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
