// https://testing-core.deploy.kuarasoftware.es/

export const API = {
	locations: {
    create: `/location`,
    edit: `/location/`,
    delete: `/location/`,
    list: `/location`,
  },
	businesss: {
    create: `/business`,
    edit: `/business/`,
    delete: `/business/`,
    list: `/business`,
  },
  connections: {
    create: `/connection`,
    edit: `/connection/`,
    delete: `/connection/`,
    list: `/connection`,
    connect: `/connect`,
    browse: `/browse`,
    disconnect: `/disconnect`,
  },
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/user`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
};
